import facebook from "../../assets/icons/connected-accounts/facebook.svg";
// import mpesa from "../../assets/icons/connected-accounts/mpesa.svg";
import bulkSms from "../../assets/icons/connected-accounts/bulk-sms.svg";

const ACCOUNTS = [
  {
    id: 1,
    name: "Facebook",
    icon: facebook,
    description:
      "Create and manage Facebook Ads for your business right from the tappi app and track their performance seamlessly.",
    status: false,
    link: "/account/connected-accounts/facebook",
  },
  // {
  //   id: 2,
  //   name: "MPESA",
  //   icon: mpesa,
  //   description:
  //     "Request money and receive payments securely from your customers.",
  //   status: false,
  //   link: "/account/connected-accounts/mpesa",
  // },
  {
    id: 3,
    name: "Bulk SMS Sender ID",
    description:
      "Add credibility to your bulk SMS messages and help your customers recognize your business by creating a custom Sender ID.",
    icon: bulkSms,
    status: true,
    link: "/account/connected-accounts/bulk-sms",
  },
];

export default ACCOUNTS;
